<template>
  <div class="order-ops">
    <a-card :bodyStyle="{ padding: 0 }">
      <template #title>
        订单ID:{{ orderId }}
      </template>
      <a-select v-model:value="opsDataSubType" @change="handleChange" style="width: 230px;margin-left:22px" allowClear
        placeholder="选择修改类型">
        <a-select-option v-for="item in opsDataSubTypes" :key="item.value">{{ item.label }}</a-select-option>
      </a-select>
      <component @success="success" :orderVehicles="orderVehicles" :label="label" :is="componentEdit" :subType="opsDataSubType" v-if="opsDataSubType >= 0"
        :orderId="orderId" />
    </a-card>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import OrderPickInfoEdit from '@/views/opsCenter/compents/OrderPickInfoEdit'
import OrderClosePick from '@/views/opsCenter/compents/OrderClosePick'
import OrderCloseSend from '@/views/opsCenter/compents/OrderCloseSend'
import OrderOpenPick from '@/views/opsCenter/compents/OrderOpenPick'
import OrderOpenSend from '@/views/opsCenter/compents/OrderOpenSend'
import OrderProducts from '@/views/opsCenter/compents/OrderProducts'
import OrderEndPosition from '@/views/opsCenter/compents/OrderEndPosition'
import OrderBrands from '@/views/opsCenter/compents/OrderBrands'
import OrderRemark from '@/views/opsCenter/compents/OrderRemark'
import OrderChangeFee from '@/views/opsCenter/compents/OrderChangeFee'
import OrderVinNo from '@/views/opsCenter/compents/OrderVinNo'
import OrderCloseRefund from '@/views/opsCenter/compents/OrderCloseRefund'
import OrderOpenInvoice from '@/views/opsCenter/compents/OrderOpenInvoice'
import OrderOpenRefund from '@/views/opsCenter/compents/OrderOpenRefund'
import OrderSendAddress from '@/views/opsCenter/compents/OrderSendAddress'
import OrderSettlement from '@/views/opsCenter/compents/OrderSettlement'
import OrderSettlementBook from '@/views/opsCenter/compents/OrderSettlementBook'
import OrderSettlementMonth from '@/views/opsCenter/compents/OrderSettlementMonth'
import OrderCancel from '@/views/opsCenter/compents/OrderCancel'
import OrderCloseInvoice from '@/views/opsCenter/compents/OrderCloseInvoice'
import OrderChangeCustomer from '@/views/opsCenter/compents/OrderChangeCustomer'
import OrderChangeCarrier from '@/views/opsCenter/compents/OrderChangeCarrier'
import OrderSettlementPay from '@/views/opsCenter/compents/OrderSettlementPay'
import OrderSourceMan from '@/views/opsCenter/compents/OrderSourceMan'
import OrderRecordMan from '@/views/opsCenter/compents/OrderRecordMan'
import OrderTime from '@/views/opsCenter/compents/OrderTime'
import { message } from 'ant-design-vue'


export default {
  props: { orderId: { type: String, default: '' }, label:{ type:String, default:''},isInvoice:{type:Number, default:null},orderVehicles:{ type: Array, default: [] }},
  emits: ['detail'],
  components: {
    OrderPickInfoEdit,
    OrderClosePick,
    OrderCloseSend,
    OrderOpenSend,
    OrderOpenPick,
    OrderProducts,
    OrderEndPosition,
    OrderBrands,
    OrderRemark,
    OrderChangeFee,
    OrderVinNo,
    OrderCloseRefund,
    OrderOpenInvoice,
    OrderOpenRefund,
    OrderSendAddress,
    OrderSettlement,
    OrderSettlementBook,
    OrderSettlementMonth,
    OrderCloseInvoice,
    OrderChangeCustomer,
    OrderChangeCarrier,
    OrderSettlementPay,
    OrderSourceMan,
    OrderRecordMan,
    OrderTime
  },
  setup (props, { emit }) {
    const state = reactive({
      opsDataSubType: undefined,
      componentEdit: null,
      orderId: props.orderId,
      loading: false,
      opsDataSubTypes: [
        { label: '修改取车人', value: 0 },
        { label: '关闭取车', value: 1 },
        { label: '关闭送车', value: 2 },
        { label: '打开取车', value: 3 },
        { label: '打开送车', value: 4 },
        { label: '修改送车地址', value: 15 },
        { label: '修改产品', value: 5 },
        { label: '修改目的地', value: 6 },
        { label: '修改车型', value: 7 },
        { label: '修改车架号', value: 8 },
        { label: '修改订单备注', value: 9 },
        { label: '修改费用', value: 19 },
        { label: '取消返款', value: 11 },
        { label: '打开开票', value: 12 },
        { label: '打开返款', value: 13 },
        { label: '到付改为预付X到付X', value: 16 },
        { label: '结算类型改记账', value: 17 },
        { label: '结算类型改月结', value: 18 },
        { label: '取消订单', value: 20 },
        { label: '取消开票', value: 21 },
        { label: '修改客户', value: 22 },
        // { label: '修改承运公司', value: 23 },
        { label: '结算类型改到收', value: 24 },
        { label: '修改分单员', value: 25 },
        { label: '修改录单员', value: 26 },
        { label: '修改预约时间', value: 27 }
      ],
      form: {}
    })
    const handleChange = (e, v) => {
      props.orderVehicles.forEach(item=>{
        item.checked = false
      })
      if(state.opsDataSubType==12&&props.isInvoice == 1){
         message.warn('订单已开票')
         state.componentEdit = null
         state.opsDataSubType = null
         return
      }
      switch (state.opsDataSubType) {
        case 0:
          state.componentEdit = OrderPickInfoEdit
          break
        case 1:
          state.componentEdit = OrderClosePick
          break
        case 2:
          state.componentEdit = OrderCloseSend
          break
        case 3:
          state.componentEdit = OrderOpenPick
          break
        case 4:
          state.componentEdit = OrderOpenSend
          break
        case 5:
          state.componentEdit = OrderProducts
          break
        case 6:
          state.componentEdit = OrderEndPosition
          break
        case 7:
          state.componentEdit = OrderBrands
          break
        case 8:
          state.componentEdit = OrderVinNo
          break
        case 9:
          state.componentEdit = OrderRemark
          break
        case 19:
          state.componentEdit = OrderChangeFee
          break
        case 11:
          state.componentEdit = OrderCloseRefund
          break
        case 12:
          state.componentEdit = OrderOpenInvoice
          break
        case 13:
          state.componentEdit = OrderOpenRefund
          break
        case 15:
          state.componentEdit = OrderSendAddress
          break
        case 16:
          state.componentEdit = OrderSettlement
          break
        case 17:
          state.componentEdit = OrderSettlementBook
          break
        case 18:
          state.componentEdit = OrderSettlementMonth
          break
        case 20:
          state.componentEdit = OrderCancel
          break
        case 21:
          state.componentEdit = OrderCloseInvoice
          break
        case 22:
          state.componentEdit = OrderChangeCustomer
          break
        case 23:
          state.componentEdit = OrderChangeCarrier
          break
        case 24:
          state.componentEdit = OrderSettlementPay
          break
        case 25:
          state.componentEdit = OrderSourceMan
          break
        case 26:
          state.componentEdit = OrderRecordMan
          break
          case 27:
          state.componentEdit = OrderTime
          break
      }
    }
    const success = (boo) => {
      emit('close', boo)
    }
    //   onMounted(() => {
    //   console.log(1111);
    //   // reqGetSubjectList(0)
    // })
    return {
      ...toRefs(state),
      handleChange,
      success
    }
  }
}
</script>
<style lang="less" scoped>
.order-ops {
  .ant-table td {
    white-space: nowrap;
  }

  .not-archive {
    color: #999;
  }
}
</style>
