<template>
  <a-card class="card-box">
    <div class="suspension-button">
      <a-space>
        <template v-if="form.order.orderMethod === 0">
          <a-button type="link" @click="edit">修改</a-button>
          <a-popconfirm title="确定推送该订单?" @confirm="send">
            <a-button type="link">推送</a-button>
          </a-popconfirm>
        </template>
        <a-button v-if="form.order.isConfirm?.value !== 1" type="link" @click="confirmOrder">确认运输</a-button>
        <a-button type="link" @click="form.order.carrierType.value === 1 ? modalVisibleHY = true : modalVisibleHB = true">合同</a-button>
        <a-button type="link" @click="OrderModification ">订单数据修改</a-button>
      </a-space>
    </div>
    <table class="table-orderDetail">
      <thead>
        <tr>
          <th>
            <a-page-header title="订单详情" @back="backToInternal" />
          </th>
          <th>
            <ReloadOutlined @click="reload" />
          </th>
        </tr>
        <tr>

          <th class="left-td" colspan="4">
            来源：{{ insideName }}
          </th>
          <th colspan="4">
            是否使用优惠券：{{ orderVehiclesCouponAmtSum > 0 ? '是' : '否' }}
          </th>
          <th v-if="orderVehiclesCouponAmtSum > 0" colspan="4">
            优惠券金额：{{ orderVehiclesCouponAmtSum }}
          </th>

        </tr>
        <tr>
          <th class="left-td" colspan="8">
            <a-typography-paragraph type="danger" :copyable="{ text: form.order.orderId }">
              NO：{{ form.order.orderId }}
            </a-typography-paragraph>
          </th>
          <th colspan="8">
            承运公司：{{ form.order.carrierType.label }}
          </th>
          <th colspan="6">
            分单员：{{ form.order.sourceMan }}
          </th>
          <th class="right-td" colspan="8">
            录单员：{{ form.order.salesman }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <!--  委托方 -->
          <td rowspan="3" colspan="2" width="6%">
            <strong>委托方</strong>
          </td>
          <td class="label-td" colspan="2" width="5%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.orderCustomer.customerName }}
          </td>
          <!-- 交车人 -->
          <td rowspan="3" colspan="2" width="6%">
            <div style="font-weight:bold">起运地</div>
            <strong>交车人</strong>
          </td>
          <td class="label-td" colspan="2" width="6%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.name }}
          </td>
          <!-- 取车人 -->
          <td rowspan="3" colspan="2" width="6%">
            <div style="font-weight:bold">目的地</div>
            <strong>取车人</strong>
          </td>
          <td class="label-td" colspan="2" width="6%">姓名</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.name }}
          </td>
        </tr>
        <tr>
          <!--  委托方 -->
          <td class="label-td" colspan="2" width="5%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.orderCustomer.certificateNumber }}
          </td>
          <!-- 交车人 -->
          <td class="label-td" colspan="2" width="6%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.certificateNumber }}
          </td>
          <!-- 取车人 -->
          <td class="label-td" colspan="2" width="6%">证件号</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.certificateNumber }}
          </td>
        </tr>
        <tr>
          <!--  委托方 -->
          <td class="label-td" colspan="2" width="5%">电话</td>
          <td colspan="4" width="22%">{{ form.orderCustomer.customerMobile }}</td>
          <!-- 交车人 -->
          <td class="label-td" colspan="2" width="6%">电话</td>
          <td colspan="4" width="22%">
            {{ form.order.consignor.mobile }}
          </td>
          <!-- 取车人 -->
          <td class="label-td" colspan="2" width="6%">电话</td>
          <td colspan="4" width="22%">
            {{ form.order.picker.mobile }}
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">对接人</td>
          <td colspan="22" class="left-td">
            <span v-show="form.orderCustomer.businessContactPerson">对接人：{{ form.orderCustomer.businessContactPerson
            }}</span>
            &emsp;
            <span v-show="form.orderCustomer.businessContactMobile">对接人电话：{{ form.orderCustomer.businessContactMobile
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2" width="6%">起运地</td>
          <td colspan="6" width="22%">
            <div style="width:100%;white-space:normal;word-wrap:break-word;word-break:break-all;">{{ form.order.startAddress.details }}</div>
          </td>
          <td class="label-td" colspan="2" width="6%">目的地</td>
          <td colspan="6" width="22%">
            <div style="width:100%;white-space:normal;word-wrap:break-word;word-break:break-all;"> {{ form.order.endAddress.details }}</div>
          </td>
          <td class="label-td" colspan="2" width="6%">所属区域</td>
          <td colspan="6" width="22%">
            {{ form.order.officeOrgName }}
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2" width="10%">业务类型</td>
          <td colspan="6">
            {{ form.order.orderFund.label }}
          </td>
          <td class="label-td" colspan="2" width="6%">结算方式</td>
          <td colspan="6">
            <a-row>
              <a-col :span="8">
                {{ form.order.orderSettlement.label }}
              </a-col>
              <a-col :span="16" v-if="form.order.orderSettlement.value === 3">
                预付 <a-typography-text type="danger"> ￥ {{ form.order.advanceCharge }}</a-typography-text>
                到收 <a-typography-text type="danger"> ￥ {{ form.order.paymentArrival }}</a-typography-text>
              </a-col>
              <a-col :span="16" v-if="form.order.orderSettlement.value === 5">
                记账期限
                <a-typography-text type="danger"> {{ form.order.deadline }} 天</a-typography-text>
              </a-col>
            </a-row>
          </td>
          <td class="label-td" colspan="2">产品服务</td>
          <td colspan="6">
            <a-row>
              <a-col :span="12">
                {{ form.order.productName || '无产品' }}
              </a-col>
              <a-col :span="12" v-if="form.order.productId === '1392734185333278722'">
                时效
                <a-typography-text type="danger"> {{ form.order.aging }} 天</a-typography-text>
              </a-col>
            </a-row>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">发票</td>
          <td colspan="2" class="left-td">
            {{ form.order.isInvoice.label }}
          </td>
          <td colspan="6">税率</td>
          <td colspan="6" class="left-td">
            <span class=" m-l3" v-if="form.order.taxRate&&form.order.isInvoice?.value==1">{{ form.order.taxRate + '%' }}</span>
          </td>
          <td colspan="2">税目</td>
          <td colspan="6" class="left-td">
            <span style="margin-left: 90px;" v-if="form.order.taxItem&&form.order.isInvoice?.value==1"> {{ form.order.taxItem  }}</span>
          </td>
        </tr>
        <tr>
          <td class="label-td" colspan="2">返款</td>
          <td colspan="2" class="left-td">
            {{ form.order.isRefund.label }}
          </td>
          <template v-if="form.order.isRefund.value">
            <td class="label-td" colspan="2" width="6%">返款金额</td>
            <td colspan="2">
              <a-typography-text type="danger">￥ {{ form.order.refundFee }}</a-typography-text>
            </td>
            <td class="label-td" colspan="2" width="6%">收款人</td>
            <td colspan="6">
              {{ form.order.refunder }}
            </td>
            <td class="label-td" colspan="2" width="6%">返款电话</td>
            <td colspan="6">
              {{ form.order.refunderMobile }}
            </td>
          </template>
          <td v-else colspan="20" />
        </tr>
        <tr>
          <td class="label-td" colspan="2">总金额</td>
          <td colspan="22" class="left-td">
            <a-typography-text type="danger"> ￥ {{ totalFee }}</a-typography-text>
            大写： <a-typography-text type="danger"> {{ totalFeeChar }} </a-typography-text>
          </td>
        </tr>
        <tr>
          <td colspan="2" width="6%" class="label-td">备注</td>
          <td colspan="22" class="left-td">
            {{ form.order.remark }}
          </td>
        </tr>
        <tr>
          <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">等通知放车</span></td>
          <td colspan="5">
            <div style="display:flex;justify-content: left;align-items:center">
              {{ form.order.isNoticeReleaseCar?.value === 1 ? '是' : '否' }}
            </div>
          </td>
          <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">交车条</span></td>
          <td colspan="6">
            <div style="display:flex;justify-content: left;align-items:center">
              {{ form.order.isDeliveryCard?.value === 1 ? '是' : '否' }}
            </div>
          </td>
          <td colspan="2" width="6%" class="label-td"><span style="font-size: 15px;">验车打款</span></td>
          <td colspan="7">
            <div style="display:flex;justify-content: left;align-items:center">
              {{ form.order.isInspectionPay?.value === 1 ? '是' : '否' }}
              <div class="isInspectionPay-div" v-show="form.order.isInspectionPay?.value === 1" style="flex:1">
                <a-tooltip>
                  <template #title>{{ form.order.inspectPayRemark || '无备注' }}</template>
                  <a>备注信息</a>
                </a-tooltip>
              </div>
            </div>
          </td>
        </tr>
        <template v-for="(item, index) in  form.orderVehicles" :key="index">
          <tr>
            <td class="label-td" colspan="24" />
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" rowspan="5">
              <a-typography-title :level="4">车辆{{ index + 1 }}</a-typography-title>
            </td>
            <td class="label-td" colspan="2">车型</td>
            <td colspan="4" width="19%">
              {{ item.brand }} · {{ item.model }} {{ item.vehicleType?.label }}
            </td>
            <td class="label-td" colspan="2">车牌/车架号</td>
            <td colspan="4" width="14%">
              <a-typography-paragraph copyable>{{ item.vinNo }}</a-typography-paragraph>
            </td>
            <td class="label-td" colspan="2" width="6%">车辆状况</td>
            <td colspan="4">
              {{ item.status.label }}
            </td>
            <td class="label-td" colspan="2" width="8%">运费（元）</td>
            <td colspan="4">
              <a-typography-text type="danger">￥ {{ item.freight }}</a-typography-text>
            </td>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2">
              保险
            </td>
            <template v-if="item.insuranceCompany.value === 1">
              <td colspan="21" class="left-td">
                {{ item.insuranceCompany.label }}
              </td>
            </template>
            <template v-else>
              <td colspan="4">
                {{ item.insuranceCompany.label }}
              </td>
              <td class="label-td" colspan="4">保额（万元）</td>
              <td colspan="4" width="12%">
                {{ item.insuranceFee }}
              </td>
              <td class="label-td" colspan="4">保费（元）</td>
              <td colspan="5" width="14%">
                <a-typography-text type="danger">￥ {{ item.insurancePremium }}</a-typography-text>
              </td>
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2" width="8%">预约时间</td>
            <td class="label-td" colspan="2" width="8%">
              {{ item.pickAppointTime }}
            </td>
            <td colspan="20"></td>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2" width="8%">取车</td>
            <td colspan="2" width="8%">
              <!--              {{item.isPlaceIn.label}}-->
              <span v-if="item.isPlaceIn?.value === 1">{{ item.pickType?.label || '是' }}</span>
            </td>
            <!-- 客户送车 -->
            <!-- <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
              {{ item.isCusSend.value === 1 ? '是' : '否' }}
            </td> -->
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%"></td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%">
            </td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" colspan="2" width="8%"><span v-show="item.isPlaceIn.value === 1">客户送车门店</span></td>
            <td v-show="item.isPlaceIn.value === 0" class="label-td" :colspan="item.isPlaceIn.value === 0 ? 12 : 5" width="22%" style="padding:5px 2px">
              <div v-show="item.isCusSend.value === 1">
                {{ item.cusSendAdd }}
              </div>
            </td>
            <template v-if="item.isPlaceIn && item.isPlaceIn.value">
              <td class="label-td" colspan="2" width="12%">取车费（元）</td>
              <td colspan="6" width="22%">
                <a-typography-text type="danger">￥ {{ item.placeInFee }}</a-typography-text>
              </td>
              <td class="label-td" colspan="2" width="8%">取车地址</td>
              <td colspan="9" width="36%">
                {{ item.placeInAddress }}
              </td>
            </template>
            <template v-else>
              <td colspan="18" />
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2" width="8%">送车</td>
            <td colspan="2">
              <template v-if="item.isTakeOut && item.isTakeOut.value">
                <!--                {{item.isTakeOut.label}}-->
                <span v-if="item.isTakeOut?.value === 1">{{ item.takeType?.label || '是' }}</span>
              </template>
            </td>
            <!-- <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">是否到达门店</td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">
              {{ item.isCusPick.value === 1 ? '是' : '否' }}
            </td> -->
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%"></td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%">
            </td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" colspan="2" width="8%"><span v-show="item.isTakeOut.value === 1">客户取车门店</span></td>
            <td v-show="item.isTakeOut.value === 0" class="label-td" :colspan="item.isTakeOut.value === 1 ? 6 : 13" width="8%" style="padding:5px 2px">
              {{ item.cusPickAdd }}
            </td>
            <template v-if="item.isTakeOut && item.isTakeOut.value">
              <td class="label-td" colspan="2" width="12%">送车费（元）</td>
              <td colspan="6" width="22%">
                <a-typography-text type="danger">￥ {{ item.takeOutFee }}</a-typography-text>
              </td>
              <td class="label-td" colspan="2" width="8%">送车地址</td>
              <td colspan="8" width="36%">
                {{ item.takeOutAddress }}
              </td>
            </template>
            <template v-else>
              <td v-show="item.isTakeOut.value === 1" colspan="18" />
            </template>
          </tr>
          <tr class="vehicle-div">
            <td class="label-td" colspan="2">备注</td>
            <td colspan="22" class="left-td">
              {{ item.remark }}
            </td>
          </tr>
        </template>
        <tr>
          <th class="right-td" colspan="24">
            录入时间：{{ form.order.orderTime }}
          </th>
        </tr>
      </tbody>
    </table>
  </a-card>
  <!-- 新-华月车辆物流运输合同 -->
  <a-modal v-model:visible="modalVisibleHY" title="协议预览" :width="1200" :bodyStyle="{ height: '70vh', overflow: 'auto' }">
    <div id="printOrder">
      <div>
        <img style="width:204px;" v-if="form.order.carrierType.value === 3" src='../../../assets/images/log.png' />
        <a-typography-title :level="2" class="contract-title">车辆物流运输合同</a-typography-title>
        <div style="text-align: end;font-size:20px;"><span style="font-weight:bold">合同编号：</span><b><span style="color:red;"> {{ form.order.orderId }}</span></b></div>
        <div style="text-align: center;margin:20px 0;font-size: 24px;color:#000"><span style="margin-right:15px;">第一部分</span>运单内容</div>
        <div class="contract-top">
          <p>起运地：<span>{{ form.order.startAddress.details }}</span></p>
          <p>目的地：<span>{{ form.order.endAddress.details }}</span></p>
          <p>运输日期：<span> {{ form.order.pushTime }}</span></p>
        </div>
        <div style="border-bottom:none" class="contract-mid">
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col :span="4">
                <b>委托方(甲方)：</b>
              </a-col>
              <a-col :span="6">
                <span>
                  {{ form.orderCustomer.customerName }}
                </span>
              </a-col>
              <a-col :span="2">
                <b>证件号</b>
              </a-col>
              <a-col :span="3">
                <span>
                  {{ form.orderCustomer.certificateNumber }}
                </span>
              </a-col>
              <a-col :span="2">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                <span>
                  {{ form.orderCustomer.customerMobile }}
                </span>
              </a-col>
            </a-row>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col :span="4">
                <b>委托方指定收车人姓名：</b>
              </a-col>
              <a-col :span="6">
                {{ form.order.picker.name }}
              </a-col>
              <a-col :span="2">
                <b>证件号</b>
              </a-col>
              <a-col :span="3">
                {{ form.order.picker.certificateNumber }}
              </a-col>
              <a-col :span="2">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                {{ form.order.picker.mobile }}
              </a-col>
            </a-row>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col :span="3">
                <b>受托方(乙方):</b>
              </a-col>
              <a-col :span="5">
                {{ form.order.carrierType.label }}
              </a-col>
              <a-col :span="3.5">
                <b>统一社会信用代码:</b>
              </a-col>
              <a-col :span="5">
                <b v-if="form.order.carrierType.value === 1">91510107054943960U</b>
                <b v-if="form.order.carrierType.value === 2"></b>
              </a-col>
              <a-col :span="1.5">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                <span>{{ form.order.salesMobile }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <table class="table-orderDetail">
          <template v-for="(item, index) in  form.orderVehicles" :key="index">
            <tr v-if="index > 0">
              <td colspan="13" />
            </tr>
            <tr>
              <td class="label-td">车型</td>
              <td>{{ item.brand }} · {{ item.model }}</td>

              <td class="label-td">车架号/车牌号</td>
              <td>{{ item.vinNo }}</td>
              <td class="label-td">状况</td>
              <td>{{ item.status.label }}</td>
              <td rowspan="4">
                合计：
                <a-typography-text type="danger"> ￥ {{ item.totalFee }} </a-typography-text>
              </td>
            </tr>
            <tr>
              <td class="label-td">服务费</td>
              <td>
                <a-typography-text type="danger"> ￥ {{ item.freight }} </a-typography-text>
              </td>
              <td class="label-td">保额（万元）</td>
              <td>{{ item.insuranceFee }}</td>
              <td class="label-td">保险费</td>
              <td>
                <a-typography-text type="danger"> ￥ {{ item.insurancePremium }} </a-typography-text>
              </td>
            </tr>
            <tr>
              <td class="label-td">预约取车时间</td>
              <td>
                {{ item.pickAppointTime }}
              </td>
              <td class="label-td">取车费</td>
              <td>
                <template v-if="item.isPlaceIn">
                  <template v-if="item.isPlaceIn.value">
                    <a-typography-text type="danger"> ￥ {{ item.placeInFee }} </a-typography-text>
                  </template>
                  <template v-else>
                    {{ item.isPlaceIn.label }}
                  </template>
                </template>
              </td>
              <td class="label-td">取车地址</td>
              <td class="label-td">
                <template v-if="item.isPlaceIn && item.isPlaceIn.value">
                  {{ item.placeInAddress }}
                </template>
              </td>
            </tr>
            <tr>
              <td class="label-td">送车费</td>
              <td>
                <template v-if="item.isTakeOut.value">
                  <a-typography-text type="danger"> ￥ {{ item.takeOutFee }} </a-typography-text>
                </template>
                <template v-else>{{ item.isTakeOut.label }}</template>
              </td>
              <td class="label-td">送车地址</td>
              <td colspan="3" class="left-td">
                <template v-if="item.isTakeOut && item.isTakeOut.value">
                  {{ item.takeOutAddress }}
                </template>
              </td>
            </tr>
          </template>
        </table>
        <div style="border-top:none" class="contract-mid">
          <div class="contract-mid-value">
            <p><b>车辆其他协商事宜：{{ form.order.remark }}</b>{{ form.order.remark !== '' ? '，' : '' }}车辆外观以照片为准，车内禁止放贵重物品</p>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :span="3">
                <b>费用总计</b>
              </a-col>
              <a-col :span="3">
                <a-typography-text type="danger">{{ totalFee }} CNY</a-typography-text>
              </a-col>
              <a-col :span="3">
                <b>大写</b>
              </a-col>
              <a-col :span="3">
                <a-typography-text type="danger">{{ totalFeeChar }}</a-typography-text>
              </a-col>
              <a-col :span="3">
                <b>结算方式</b>
              </a-col>
              <a-col :span="3">
                <template v-if="form.order.orderSettlement.value === 3">
                  预付 <a-typography-text type="danger"> ￥ {{ form.order.advanceCharge }}</a-typography-text>
                  到收 <a-typography-text type="danger"> ￥ {{ form.order.paymentArrival }}</a-typography-text>
                </template>
                <template v-else>
                  {{ form.order.orderSettlement.label }}
                </template>
              </a-col>
            </a-row>
          </div>
          <!-- <div class="contract-mid-text" style="padding:10px">
            <span>备注：</span>
            <div style="display: flex;justify-content: space-around;">
              <div>开票类型：<span style="margin:0 40px 0 20px">是</span>否</div>
              <div>税率：______%</div>
            </div>
          </div> -->
          <div class="contract-mid-motion">
            <span>温馨提示：</span>
            <p>1、请签字前一定确认好车辆价值并足额投保；</p>
            <p>2、车辆托运服务中的验车环节仅对车辆外观直接可视部分进行查验，不涉及车辆内部构造以及性能的查验，同时也不对车辆内部构造零配件以及性能承担任何担保和赔偿责任；</p>
            <p>3、签字前请务必阅读该合同各条款。</p>
            <div class="contract-mid-motion-sign">
              <div>
                <b>甲方（签字）：</b>
              </div>
              <div>
                <b> 乙方（盖章）：</b>
              </div>
            </div>
          </div>
        </div>
        <!-- v-if="form.order.orderMethod === 1" -->
        <img class="contract-seal" v-if="form.order.carrierType.value === 1" src='../../../assets/images/huayueContract.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 2" src='../../../assets/images/hb.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 3" src='../../../assets/images/szhy.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 4" src='../../../assets/images/jiuzhi.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 5" src='../../../assets/images/gfrk.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 9" src='../../../assets/images/cdDQ.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 10" src='../../../assets/images/scDQ.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 11" src='../../../assets/images/scDQXH.png' />
        <div class="contract-content">
          <h1 class="item" style="font-size:24px"><span style="margin-right:15px">第二部分</span> 合同条款</h1>
          <p class="item">依据《中华人民共和国民法典》及相关法律法规的规定，甲乙双方在平等、自愿的基础上，就甲方委托乙方为甲方指定托运车辆提供运输服务。经双方友好协商，签订本协议，以资共同遵照执行。</p>
          <div class="contract-content-label item">一、运输内容</div>
          <p class="item"><span class="contract-content-label-title">1.1</span> 乙方为甲方需要运输的车辆提供运输服务、运输代理服务，乙方运输至甲方指定目的地
            。甲方应在运单内容中向乙方准确提供起运地、起运交车人、起运时间、目的地、到达目的地时间、目的地接车等信息。</p>
          <p class="item"><span class="contract-content-label-title">1.2</span>
            乙方启运后甲方变更目的地的，应另行计算运输费用及到达日期，乙方已经发生的在途里程以及因无法返程而必须的里程，应据实向乙方结算已发生费用。 </p>

          <div class="contract-content-label item">二、甲方运输要求</div>
          <p class="item"><span class="contract-content-label-title">2.1</span> 运输方式：公路运输/铁路运输/滚装船，由乙方根据运输距离和交通状况等情况予以匹配。
          </p>
          <p class="item"><span class="contract-content-label-title">2.2</span> 车辆交接</p>
          <p class="item">
            <span class="contract-content-label-title">2.2.1</span>
            甲方向乙方交付托运车辆时，双方指定交接车人员按承运交接单逐项交验。交接完毕后双方需在承运交接单签字。交验过程中经双方签字确认的车辆损伤、备件短缺等问题应在承运交接单上注明，并在相应范围内免除乙方的赔偿责任。
          </p>
          <p class="item">
            <span class="contract-content-label-title">2.2.2</span>
            乙方向甲方指定接车人在目的地移交甲方指定运输车辆时，应以承运交接单记录为依据。经甲方指定的接车人接车的同时应在承运交接单上签字确认后方可接车。
          </p>
          <p class="item">
            <span class="contract-content-label-title">2.2.3</span>
            交接验收合格后，乙方承运责任随之解除，托运车辆再出现的任何问题均与乙方无关。
          </p>
          <p class="item">
            <span class="contract-content-label-title">2.2.4</span>
            单证交接：甲方必须将符合国家规定并与该商品车运输有关的单证、资料交于乙方，供乙方在运送过程中备查，如因甲方单证、资料不符合要求原因造成的一切损失及后果均无乙方责任，由甲方承担。若乙方在运输途中丢失前述单证、资料给甲方造成损失的，由乙方承担赔偿责任。
          </p>
          <div class="contract-content-label">三、双方的权利和义务</div>
          <p class="item">
            <span class="contract-content-label-title">3.1</span>
            甲方的权利与义务
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.1</span>
            甲方在目的地验收指定运输车辆时发现车辆有承运交接单记载之外的破损，可向乙方提出，由乙方协调投保的保险公司按实际损失由保险公司向甲方进行赔偿。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.2</span>
            甲方将所需运输车辆送至起运地城市指定起运点，并由甲方指定接车人至目的地城市指定接车点提车。甲方应按时向乙方交接车辆，在乙方指定接车人到达1小时内办理车辆移交事宜，超时的由甲方向乙方支付车辆等待费。车辆到达目的地后，甲方接车人应及时办理提车事宜，超过3天无故未办理提车的，乙方不承担任何保管责任，一切损失由甲方承担。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.3</span>
            甲方应确保所托运的车辆不涉及盗抢、套牌、走私等涉案不合法车辆，并能提供证明该车辆的合法来源，使其适合运输。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.4</span>
            甲方确保所托运的车辆内严禁携带易燃、易爆、易腐蚀、放射性以及国家法律明文规定的各种管制刀具、枪械、毒品等物品。若甲方私自夹带前述物品的，被相关部门查获的，由甲方承担全部责任，若因此造成乙方经济损失、行政处罚或乙方相关人员刑事惩罚的，甲方应全额赔偿乙方一切损失并承担50万元的惩罚性违约金。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.5</span>
            甲方在车内放置的物品（质量≤100kg）需自行打包放好，需确保车辆的车窗、车门、后备箱能正常关闭，并确保能安全装卸上下车，确保所搭载的物品不影响视线。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.1.6</span>
            甲方托运的车辆内携带物品的应按物品清单予以清点记载，原则上单价价值超过100元以上物品不随车携带，所有物品（零散小物品除外）要与乙方指定接车人签订书面交接手续，乙方认为确不适合运输的，有权拒绝运输。未经乙方指定接车人签字确认的物品不负责保管和承担赔付责任。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.2</span>
            乙方的权利与义务
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.2.1</span>
            乙方应确保“安全、准时、快捷”地将甲方委托运输服务的车辆运达目的地、交付于甲方指定的接车人，并将双方确认的随车物品及有关的资料一并交付。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.2.2</span>
            乙方运输车辆在抵达目的地时，乙方有义务在第一时间通知甲方来提车并验车，提车人员由甲方指定，乙方应当严格审核提车人员的身份和运单等信息相匹配后才交接车辆，否则一切责任由乙方承担，甲方如需更换提车人信息，需在车辆到达目的地之前书面告知乙方，否则乙方按运单交付，乙方不承担甲方所谓交付错误造成的损失。
          </p>
          <p class="item">
            <span class="contract-content-label-title">3.2.3</span>
            乙方将车辆运至甲方指定目的地后，如无法联系接车人或接车人无故拒收的，乙方应当立即通知甲方，并按照甲方要求处理，不得擅自交付第三人或以其他方式处理车辆。
          </p>
          <p class="item"><br /></p>
          <div class="contract-content-label">四、保险及投保</div>
          <p class="item">
            <span class="contract-content-label-title">4.1</span>
            甲方有义务为所托运车辆足额投保并支付保费，甲方拒绝投保或不足额投保（如少报车辆价格等）导致的损失全部由甲方自行承担。投保事宜由甲方委托乙方统一办理，甲方应据实向乙方或保险公司提供车辆实际价格，并按保险公司核定的金额支付保费给乙方，由乙方代为向保险公司缴纳。
          </p>
          <p class="item">
            <span class="contract-content-label-title">4.2</span>
            投保金额为车辆实际价格，新车价格不超过厂家在官网公布的销售价格。二手车投保时，保额由甲方根据甲方委托车辆所购买商业险投保额为标准进行投保，最高不超过新车购置价格。
          </p>
          <p class="item">
            <span class="contract-content-label-title">4.3</span>
            车辆运输险种为：国内水路，陆路货物运输保险条款综合险或基本险。
          </p>
          <p class="item">
            <span class="contract-content-label-title">4.4</span>
            商品车可承保货物运输综合险，含牌机动车以及外迁车辆只可承保基本险。综合险承保范围为：交通意外、火灾爆炸、自然灾害、装卸货、雨淋、挤压、震动；基本险承保范围为：交通意外、火灾爆炸、自然灾害、装卸货。
          </p>
          <p class="item">
            <span class="contract-content-label-title">4.5</span>
            上述保单内容均剔除刮蹭擦痕和锈损。
          </p>
          <div class="contract-content-label">五、免赔、理赔、赔偿</div>
          <p class="item">
            <span class="contract-content-label-title">5.1</span>
            针对轿运车运输风险过大，对于足额缴纳保险费的客户针对运输途中意外发生火灾事故甲方给予乙方绝对免赔20%。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.2</span>
            下列原因造成的损失，乙方不承担赔偿责任：
          </p>
          <p class="item">
            a、属于政府征用或罚没的；b、货物本身的性质所引起的变质、减量、破坏或毁坏；c、货物的合理损耗；d、路途堵车造成的延误；e、托车人或收车人的过失；f、甲方或约定收车方已按规定接受车辆，事后发现车辆有损或随车物品缺失的；g、不可抗力事件。合同一方由于自然灾害、战争和其他由双方认可的不可抗力事件影响到合同履行时，应采取电话、电报或传真等形式通知另一方，当不可抗力事件停止或消除后，双方应立即恢复合同正常履行。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.3</span>
            若甲方已经自行办理运输保险，因运输原因导致甲方车辆出险，由甲方保险公司承担相关责任，乙方不承担任何责任但有义务协助甲方，运费不免。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.4</span>
            如乙方已经进行投保，则车辆出险后按照乙方所投保保险公司赔偿标准进行赔偿，甲方予以配合，乙方不再额外进行赔偿。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.5</span>
            如双方对运输的车辆均没有办理运输保险的，一旦车辆发生划痕、碰撞等现象，乙方将负责车辆维修，维修标准由维修店予以核定。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.6</span>
            甲方接车人就汽车损伤情况应如实地在承运交接单上详细表书并签字后，方作为赔偿的依据，如双方对于损失情况的描述不能达成一致或者就导致损伤的原因有争议的，共同聘请第三方独立鉴定机构予以鉴定，相关鉴定费由发起方承担。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.7</span>
            车辆损伤具体维修及赔付按如下标准：
          </p>
          <p class="item">
            <span class="contract-content-label-title">a. </span>
            双方确认若发生车辆全损的情况，最高赔付金额不高于按本合同约定的保额。
          </p>
          <p class="item">
            <span class="contract-content-label-title">b. </span>
            车辆发生新的刮蹭擦痕擦挂等轻微事故的，以修复为准，甲方不得要求更换或完全重新喷漆等。被运车辆原有旧伤而导致的锈损保险公司和乙方均不承担修复和其他责任。
          </p>
          <p class="item">
            <span class="contract-content-label-title">c. </span>
            车辆发生碰撞、倒塌等较大事故的，以修复为主，不能修复按全损计。
          </p>
          <p class="item">
            以上各项修复标准根据车辆的新旧程度，原则上按照：自首次上牌一年内的以4s店维修标准予以核定维修费，一年以上的（含一年）以定价修理厂维修标准予以核定维修费。保险公司或者乙方指定的相关维修厂定损后，甲方应在2日内予以确认并配合修理，因甲方单方对定损项或维修标准提出异议而导致车辆未及时维修并交付的，相关损失（包括但不限于逾期交付违约金、停运损失、租车费用、事故车辆的停车费用等）由甲方承担。车辆维修完毕，乙方不再就车损及车辆因受损导致折价等作出赔付或补偿。
          </p>
          <p class="item">
            <span class="contract-content-label-title">5.8</span>
            车辆出现重大事故，如车辆价值或车损价格不能认定或各方认定价值出入过大的，双方协商同意可提请第三方评估机构代为评估车辆价值和残值，相关评估费用由责任方承担。
          </p>
          <div class="contract-content-label">六、违约责任</div>
          <p class="item">
            <span class="contract-content-label-title">6.1</span>
            如甲方不按时支付运费，乙方有权不向甲方接车人交付车辆，甲方每逾期一天，需支付应付而未付款项1%的违约金。同时乙方有权停止为甲方提供服务，并有权随时终止合同关系，甲方还需承担乙方为主张权利而支出的包括但不限于违约金、律师费、诉讼费、保全费、保全担保费等全部费用。
          </p>
          <p class="item">
            <span class="contract-content-label-title">6.2</span>
            甲方所委托运输车辆在委托过程中若出现任何事故，在保险公司或乙方赔付后，甲方应足额支付运费，乙方有权在应付赔款中予以扣减。
          </p>
          <div class="contract-content-label item">七、保密条款</div>
          <p class="item">
            甲乙双方对从对方处获取的资料和相关的商业机密、未公开的信息等负有保密义务，并应采取一切合法的措施以使其所接受的资料免于被无关人员接触。
          </p>
          <div class="contract-content-label item">八、争议解决</div>
          <p class="item">
            因本合同引起的或与本合同有关的任何争议，由甲乙双方协商解决，也可由有关部门调解。协商或调解不成的，依法向乙方所在地有管辖权的人民法院起诉。
          </p>
          <div class="contract-content-label item">九、附则</div>
          <p class="item">
            <span class="contract-content-label-title">9.1</span>
            本合同一式二份，甲乙双方各执一份，具有同等法律效力。
          </p>
          <p class="item">
            <span class="contract-content-label-title">9.2</span>
            本合同自双方签字/盖章或电子送达起生效，双方未提出异议视作认可并接受合同条款。甲方通过电子平台下单的，甲方下单完成本合同即生效。电子合同、传真件、扫描件具有同等法律效力。
          </p>
          <div class="item" style="margin-top:25px">
            <span style="color:#000;font-weight:bold">特别提示：为充分保证您的权益，请您仔细阅读本合同。本合同系运单内容的有效组成部分，甲方签署运单或通过电子下单方式下单的视为已经仔细阅读本合同内容并无任何异议。</span>
          </div>
          <div class="item" style="margin-bottom: 20px;">(以下无正文)</div>
          <div class="item" style="padding-right:150px">
            <div style="display: flex;justify-content: space-between;">
              <span>甲方（签字/盖章）</span>
              <div style="position: relative;">
                <span>乙方（盖章）</span>
                <img class="bottom-img" v-if="form.order.carrierType.value === 1" src='../../../assets/images/huayueContract.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 2" src='../../../assets/images/hb.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 3" src='../../../assets/images/szhy.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 4" src='../../../assets/images/jiuzhi.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 5" src='../../../assets/images/gfrk.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 9" src='../../../assets/images/cdDQ.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 10" src='../../../assets/images/scDQ.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 11" src='../../../assets/images/scDQXH.png' />
              </div>
            </div>
            <p style="display: flex;justify-content: space-between;">
              <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
              <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
            </p>
          </div>
          <div style="height:50px"></div>
        </div>
      </div>
    </div>
    <template #footer>
      <a-space>
        <a-input addon-before="如果合同文字被分割，输入0-50可微调，以下载为准" v-model:value="checkNum" type='number'></a-input>
        <a-button @click="pdfDownload">下载</a-button>
        <a-button type="primary" v-print="'#printOrder'">打印</a-button>
      </a-space>
    </template>
  </a-modal>
  <!-- 新-恒宝运输代理服务合同 -->
  <a-modal v-model:visible="modalVisibleHB" title="协议预览" :width="1200" :bodyStyle="{ height: '70vh', overflow: 'auto' }">
    <div id="printOrder">
      <div>
        <img style="width:204px;" v-if="form.order.carrierType.value === 3" src='../../../assets/images/log.png' />
        <a-typography-title :level="2" class="contract-title">运输代理服务合同</a-typography-title>
        <div style="font-size:20px;"><span style="font-weight:bold">合同编号：</span><b><span style="color:red;"> {{
          form.order.orderId }}</span></b></div>
        <div style="text-align: center;margin:20px 0;font-size: 24px;color:#000"><span style="margin-right:15px;">第一部分</span>服务内容</div>
        <div class="contract-top">
          <p>起运地：<span>{{ form.order.startAddress.details }}</span></p>
          <p>目的地：<span>{{ form.order.endAddress.details }}</span></p>
          <p>运输日期：<span> {{ form.order.pushTime }}</span></p>
        </div>
        <div style="border-bottom:none" class="contract-mid">
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :span="4">
                <b>委托方(甲方):</b>
              </a-col>
              <a-col :span="6">
                <span>
                  {{ form.orderCustomer.customerName }}
                </span>
              </a-col>
              <a-col :span="2">
                <b>证件号</b>
              </a-col>
              <a-col :span="3">
                <span>
                  {{ form.orderCustomer.certificateNumber }}
                </span>
              </a-col>
              <a-col :span="2">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                <span>
                  {{ form.orderCustomer.customerMobile }}
                </span>
              </a-col>
            </a-row>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :span="4">
                <b>委托方指定收车人姓名</b>
              </a-col>
              <a-col :span="6">
                {{ form.order.picker.name }}
              </a-col>
              <a-col :span="2">
                <b>证件号</b>
              </a-col>
              <a-col :span="3">
                {{ form.order.picker.certificateNumber }}
              </a-col>
              <a-col :span="2">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                {{ form.order.picker.mobile }}
              </a-col>
            </a-row>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :span="3">
                <b>受托方(乙方):</b>
              </a-col>
              <a-col :span="5">
                {{ form.order.carrierType.label }}
              </a-col>
              <a-col :span="3.5">
                <b>统一社会信用代码</b>
              </a-col>
              <a-col :span="5">
                <b v-if="form.order.carrierType.value === 1">91510107054943960U</b>
                <b v-if="form.order.carrierType.value === 2"></b>
              </a-col>
              <a-col :span="1.5">
                <b>联系电话</b>
              </a-col>
              <a-col :span="3">
                <span>{{ form.order.salesMobile }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <table class="table-orderDetail">
          <template v-for="(item, index) in  form.orderVehicles" :key="index">
            <tr v-if="index > 0">
              <td colspan="13" />
            </tr>
            <tr>
              <td class="label-td">车型</td>
              <td>{{ item.brand }} · {{ item.model }}</td>

              <td class="label-td">车架号/车牌号</td>
              <td>{{ item.vinNo }}</td>
              <td class="label-td">状况</td>
              <td>{{ item.status.label }}</td>
              <td rowspan="4">
                合计：
                <a-typography-text type="danger"> ￥ {{ item.totalFee }} </a-typography-text>
              </td>
            </tr>
            <tr>
              <td class="label-td">服务费</td>
              <td>
                <a-typography-text type="danger"> ￥ {{ item.freight }} </a-typography-text>
              </td>
              <td class="label-td">保额（万元）</td>
              <td>{{ item.insuranceFee }}</td>
              <td class="label-td">保险费</td>
              <td>
                <a-typography-text type="danger"> ￥ {{ item.insurancePremium }} </a-typography-text>
              </td>
            </tr>
            <tr>
              <td class="label-td">取车费</td>
              <td>
                <template v-if="item.isPlaceIn">
                  <template v-if="item.isPlaceIn.value">
                    <a-typography-text type="danger"> ￥ {{ item.placeInFee }} </a-typography-text>
                  </template>
                  <template v-else>
                    {{ item.isPlaceIn.label }}
                  </template>
                </template>
              </td>
              <td class="label-td">取车地址</td>
              <td class="label-td">
                <template v-if="item.isPlaceIn && item.isPlaceIn.value">
                  {{ item.placeInAddress }}
                </template>
              </td>
            </tr>
            <tr>
              <td class="label-td">送车费</td>
              <td>
                <template v-if="item.isTakeOut.value">
                  <a-typography-text type="danger"> ￥ {{ item.takeOutFee }} </a-typography-text>
                </template>
                <template v-else>{{ item.isTakeOut.label }}</template>
              </td>
              <td class="label-td">送车地址</td>
              <td colspan="3" class="left-td">
                <template v-if="item.isTakeOut && item.isTakeOut.value">
                  {{ item.takeOutAddress }}
                </template>
              </td>
            </tr>
          </template>
        </table>
        <div style="border-top:none" class="contract-mid">
          <div class="contract-mid-value">
            <!--          <p>车辆外观： 完好、 缺少、 划痕、 擦伤、 裂痕、 凹陷、 掉漆、 磕碰、 脱落、 其他、</p>-->
            <!--          <p>车辆外观具体验车图片参考合同附件一：验车单</p>-->
            <p><b>车辆其他协商事宜：{{ form.order.remark }}</b>{{ form.order.remark !== '' ? '，' : '' }}车辆外观以照片为准，车内禁止放贵重物品</p>
          </div>
          <div class="contract-mid-label">
            <a-row type="flex" align="middle" justify="space-around">
              <a-col :span="3">
                <b>费用总计</b>
              </a-col>
              <a-col :span="3">
                <a-typography-text type="danger">{{ totalFee }} CNY</a-typography-text>
              </a-col>
              <a-col :span="3">
                <b>大写</b>
              </a-col>
              <a-col :span="3">
                <a-typography-text type="danger">{{ totalFeeChar }}</a-typography-text>
              </a-col>
              <a-col :span="3">
                <b>结算方式</b>
              </a-col>
              <a-col :span="3">
                <template v-if="form.order.orderSettlement.value === 3">
                  预付 <a-typography-text type="danger"> ￥ {{ form.order.advanceCharge }}</a-typography-text>
                  到收 <a-typography-text type="danger"> ￥ {{ form.order.paymentArrival }}</a-typography-text>
                </template>
                <template v-else>
                  {{ form.order.orderSettlement.label }}
                </template>
              </a-col>
            </a-row>
          </div>
          <div class="contract-mid-motion">
            <span>温馨提示：</span>
            <p>1、请签字前一定确认好车辆价值并足额投保；</p>
            <p>2、车辆托运服务中的验车环节仅对车辆外观直接可视部分进行查验，不涉及车辆内部构造以及性能的查验，同时也不对车辆内部构造零配件以及性能承担任何担保和赔偿责任；</p>
            <p>3、签字前请务必阅读该合同各条款。</p>
            <div class="contract-mid-motion-sign">
              <div>
                <b>甲方（签字）：</b>
              </div>
              <div>
                <b> 乙方（盖章）：</b>
              </div>
            </div>
          </div>
        </div>
        <!-- v-if="form.order.orderMethod === 1" -->
        <img class="contract-seal" v-if="form.order.carrierType.value === 1" src='../../../assets/images/huayueContract.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 2" src='../../../assets/images/hb.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 3" src='../../../assets/images/szhy.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 4" src='../../../assets/images/jiuzhi.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 5" src='../../../assets/images/gfrk.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 9" src='../../../assets/images/cdDQ.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 10" src='../../../assets/images/scDQ.png' />
        <img class="contract-seal" v-if="form.order.carrierType.value === 11" src='../../../assets/images/scDQXH.png' />
        <div class="contract-content">
          <h1 style="font-size:24px"><span style="margin-right:15px">第二部分</span> 合同条款</h1>
          <p>依据《中华人民共和国民法典》及相关法律法规的规定，甲乙双方在平等、自愿的基础上，就乙方为甲方需要运输的车辆提供运输代理服务等事宜，签订本合同，以资共同遵照执行。</p>
          <div class="contract-content-label">一、<span>服务</span>内容</div>
          <p><span class="contract-content-label-title">1.1</span>
            乙方为甲方需要运输的车辆提供运输咨询服务、运输代理服务、物流智能系统技术服务、协助完成甲方车辆运输事宜。
          </p>
          <p><span class="contract-content-label-title">1.2</span>
            甲方委托乙方对本合同约定车辆进行运输代理服务，乙方为甲方匹配合适承运人予以运输至甲方的目的地 。甲方应在运单内容中向乙方提供起运地、起运交车人、起运时间、目的地、到达目的地时间、目的地接车等信息。
          </p>
          <div class="contract-content-label">二、甲方运输要求</div>
          <p><span class="contract-content-label-title">2.1</span> 运输方式：公路运输/铁路运输/滚装船，由乙方根据运输距离和交通状况等情况予以匹配。</p>
          <p><span class="contract-content-label-title">2.2</span> 车辆交接</p>
          <p>
            <span class="contract-content-label-title">2.2.1</span>
            甲方向承运人交车（甲方指定运输车辆）时，双方指定交接车人员按承运交接单逐项交验。交接完毕后双方需在承运交接单签字。交验过程中经双方签字确认的车辆损伤、备件短缺等问题应在承运交接单上注明，并在相应范围内免除乙方及承运人的赔偿责任。
          </p>
          <p>
            <span class="contract-content-label-title">2.2.2</span>
            承运人向甲方指定接车人在目的地移交甲方指定运输车辆时，应以承运交接单记录为依据。经甲方指定的接车人接车的同时应在承运交接单上签字确认后方可接车。
          </p>
          <p>
            <span class="contract-content-label-title">2.2.3</span>
            交接验收合格后，承运人承运责任随之解除，同时乙方的服务已全部完成，托运车辆再出现的任何问题均与承运方及乙方无关。
          </p>
          <p>
            <span class="contract-content-label-title">2.2.4</span>
            单证交接：甲方必须将符合国家规定并与该商品车运输有关的单证、资料交于承运人，供承运人在运送过程中备查，如因甲方单证、资料不符合要求原因造成的一切损失及后果均无乙方及承运人责任，由甲方承担。若承运人在运输途中丢失前述单证、资料给甲方造成损失的，由承运人及乙方承担赔偿责任。
          </p>
          <div class="contract-content-label">三、双方的权利和义务</div>
          <p>
            <span class="contract-content-label-title">3.1</span>
            甲方的权利与义务
          </p>
          <p>
            <span class="contract-content-label-title">3.1.1</span>
            甲方在目的地验收指定运输车辆时发现车辆有承运交接单记载之外的破损可向乙方提出，由乙方协调投保的保险公司按实际损失由保险公司向甲方进行赔偿。
          </p>
          <p>
            <span class="contract-content-label-title">3.1.2</span>
            甲方将所需运输车辆送至起运地城市指定起运点，并由甲方指定接车人至目的地城市指定接车点提车。甲方应按时向承运人交接车辆，在承运人指定接车人到达1小时内办理车辆移交事宜，超时的由甲方向承运人支付车辆等待费。车辆到达目的地后，甲方接车人应及时办理提车事宜，超过3天无故未办理提车的，乙方不承担任何保管责任，一切损失由甲方承担。
          </p>
          <p>
            <span class="contract-content-label-title">3.1.3</span>
            甲方应确保所托运的车辆不涉及盗抢、套牌、走私等涉案不合法车辆，并能提供证明该车辆的合法来源，使其适合运输。
          </p>
          <p>
            <span class="contract-content-label-title">3.1.4</span>
            甲方确保所托运的车辆内严禁携带易燃、易爆、易腐蚀、放射性以及国家法律明文规定的各种管制刀具、枪械、毒品等物品。
          </p>
          <p>
            <span class="contract-content-label-title">3.1.5</span>
            甲方在车内放置的物品（质量≤100kg）需自行打包放好，需确保车辆的车窗、车门、后备箱能正常关闭，并确保能安全装卸上下车，确保所搭载的物品不影响视线。
          </p>
          <p style="height:20px"></p>
          <p>
            <span class="contract-content-label-title">3.1.6</span>
            甲方托运的车辆内携带物品的应按物品清单予以清点记载，原则上单价价值超过100元以上物品不随车携带，所有物品（零散小物品除外）要与承运人签订书面交接手续，承运人认为确不适合运输的，有权拒绝运输。未经承运人签字确认的物品不负责保管和承担赔付责任。
          </p>
          <p>
            <span class="contract-content-label-title">3.2</span>
            乙方的权利与义务
          </p>
          <p>
            <span class="contract-content-label-title">3.2.1</span>
            乙方应确保“安全、准时、快捷”地将甲方委托运输服务的车辆运达目的地、交付于甲方指定的接车人，并将双方确认的随车物品及有关的资料一并交付。
          </p>
          <p>
            <span class="contract-content-label-title">3.2.2</span>
            承运人车辆在抵达目的地时，乙方有义务在第一时间通知甲方来提车并验车，提车人员由甲方指定，承运人应当严格审核提车人员的身份和运单等信息相匹配后才交接车辆，否则一切责任由承运人及乙方承担，甲方如需更换提车人信息，需在车辆到达目的地之前书面告知乙方，否则承运人按运单交付，承运人及乙方均不承担甲方所谓交付错误造成的损失。
          </p>
          <p>
            <span class="contract-content-label-title">3.2.3</span>
            承运人将货物运至甲方指定地点后，如无法联系接车人或接车人无故拒收的，承运人或乙方应当立即通知甲方，并按照甲方要求处理，不得擅自交付第三人或以其他方式处理车辆。
          </p>
          <p><br /></p>
          <div class="contract-content-label">四、保险及投保</div>
          <p>
            <span class="contract-content-label-title">4.1</span>
            甲方有义务为所托运车辆足额投保并支付保费，甲方拒绝投保或不足额投保（如少报车辆价格等）导致的损失全部由甲方自行承担。投保事宜由甲方委托乙方统一办理，甲方应据实向乙方或保险公司提供车辆实际价格，并按保险公司核定的金额支付保费给乙方，由乙方代为向保险公司缴纳。
          </p>
          <p>
            <span class="contract-content-label-title">4.2</span>
            投保金额为车辆实际价格，新车价格不超过厂家在官网公布的销售价格。二手车投保时，保额由甲方根据甲方委托车辆所购买商业险投保额为标准进行投保，最高不超过新车购置价格。
          </p>
          <p>
            <span class="contract-content-label-title">4.3</span>
            车辆运输险种为：国内水路，陆路货物运输保险条款综合险或基本险。
          </p>
          <p>
            <span class="contract-content-label-title">4.4</span>
            商品车可承保货物运输综合险，含牌机动车以及外迁车辆只可承保基本险。综合险承保范围为：交通意外、火灾爆炸、自然灾害、装卸货、雨淋、挤压、震动；基本险承保范围为：交通意外、火灾爆炸、自然灾害、装卸货。
          </p>
          <p>
            <span class="contract-content-label-title">4.5</span>
            上述保单内容均剔除刮蹭擦痕和锈损。
          </p>
          <div class="contract-content-label">五、免赔、理赔、赔偿</div>
          <p>
            <span class="contract-content-label-title">5.1</span>
            针对轿运车运输风险过大，对于足额缴纳保险费的客户针对运输途中意外发生火灾事故甲方给予乙方及承运人绝对免赔20%。
          </p>
          <p>
            <span class="contract-content-label-title">5.2</span>
            下列原因造成的损失，乙方及承运人不承担赔偿责任：
          </p>
          <p>
            a、属于政府征用或罚没的；b、货物本身的性质所引起的变质、减量、破坏或毁坏；c、货物的合理损耗；d、路途堵车造成的延误；e、托车人或收车人的过失；f、甲方或约定收车方已按规定接受车辆，事后发现车辆有损或随车物品缺失的；g、不可抗力事件。合同一方由于自然灾害、战争和其他由双方认可的不可抗力事件影响到合同履行时，应采取电话、电报或传真等形式通知另一方，当不可抗力事件停止或消除后，双方应立即恢复合同正常履行。
          </p>
          <p>
            <span class="contract-content-label-title">5.3</span>
            若甲方已经自行办理运输保险，因运输原因导致甲方车辆出险，由甲方保险公司承担相关责任，乙方不承担任何责任但有义务协助甲方，服务费不免。
          </p>
          <p>
            <span class="contract-content-label-title">5.4</span>
            如乙方或承运人已经进行投保，则车辆出险后按照乙方或承运人所投保保险公司赔偿标准进行赔偿，甲方予以配合，乙方及承运人不再额外进行赔偿。
          </p>
          <p>
            <span class="contract-content-label-title">5.5</span>
            如双方对运输的车辆均没有办理运输保险的，一旦车辆发生划痕、碰撞等现象，乙方及承运人将负责车辆维修，维修标准由维修店予以核定，但维修费用最高不超过甲方支付给乙方服务费的2倍，超出部分由甲方自行承担。
          </p>
          <p>
            <span class="contract-content-label-title">5.6</span>
            甲方接车人就汽车损伤情况应如实地在承运交接单上详细表书并签字后，方作为赔偿的依据，如双方对于损失情况的描述不能达成一致或者就导致损伤的原因有争议的，共同聘请第三方独立鉴定机构予以鉴定，相关鉴定费由发起方承担。
          </p>
          <p>
            <span class="contract-content-label-title">5.7</span>
            车辆损伤具体维修及赔付按如下标准：
          </p>
          <p>
            <span class="contract-content-label-title">a. </span>
            双方确认若发生车辆全损的情况，最高赔付金额不高于按本合同约定的保额。
          </p>
          <p>
            <span class="contract-content-label-title">b. </span>
            车辆发生新的刮蹭擦痕擦挂等轻微事故的，以修复为准，甲方不得要求更换或完全重新喷漆等。被运车辆原有旧伤而导致的锈损保险公司和乙方、承运人均不承担修复和其他责任。
          </p>
          <p>
            <span class="contract-content-label-title">c. </span>
            车辆发生碰撞、倒塌等较大事故的，以修复为主，不能修复按全损计。
          </p>
          <p>
            以上各项修复标准根据车辆的新旧程度，原则上按照：自首次上牌一年内的以4s店维修标准予以核定维修费，一年以上的（含一年）以定价修理厂维修标准予以核定维修费。保险公司或者乙方、承运方指定的相关维修厂定损后，甲方应在2日内予以确认并配合修理，因甲方单方对定损项或维修标准提出异议而导致车辆未及时维修并交付的，相关损失（包括但不限于逾期交付违约金、停运损失、租车费用、事故车辆的停车费用等）由甲方承担。车辆维修完毕，乙方及承运人不再就车损及车辆因受损导致折价等作出赔付或补偿。
          </p>
          <p>
            <span class="contract-content-label-title">5.8</span>
            车辆出现重大事故，如车辆价值或车损价格不能认定或各方认定价值出入过大的，双方协商同意可提请第三方评估机构代为评估车辆价值和残值，相关评估费用由责任方承担。
          </p>
          <div class="contract-content-label">六、违约责任</div>
          <p>
            <span class="contract-content-label-title">6.1</span>
            如甲方不按时支付服务费，乙方及承运人有权不向甲方接车人交付车辆，甲方每逾期一天，需支付应付而未付款项1%的违约金。同时乙方有权停止为甲方提供服务，并有权随时终止合同关系，甲方还需承担乙方为主张权利而支出的包括但不限于违约金、律师费、诉讼费、保全费、保全担保费等全部费用。
          </p>
          <p>
            <span class="contract-content-label-title">6.2</span>
            甲方所委托运输车辆在委托过程中若出现任何事故，在保险公司、乙方或者承运人赔付后，甲方应足额支付服务费，乙方有权在应付赔款中予以扣减。
          </p>
          <div class="contract-content-label">七、保密条款</div>
          <p>
            甲乙双方对从对方处获取的资料和相关的商业机密、未公开的信息等负有保密义务，并应采取一切合法的措施以使其所接受的资料免于被无关人员接触。
          </p>
          <div class="contract-content-label">八、争议解决</div>
          <p>
            因本合同引起的或与本合同有关的任何争议，由甲乙双方协商解决，也可由有关部门调解。协商或调解不成的，依法向乙方所在地有管辖权的人民法院起诉。
          </p>
          <div class="contract-content-label">九、附则</div>
          <p>
            <span class="contract-content-label-title">9.1</span>
            本合同一式二份，甲乙双方各执一份，具有同等法律效力。
          </p>
          <p>
            <span class="contract-content-label-title">9.2</span>
            本合同自双方签字/盖章或电子送达起生效，双方未提出异议视作认可并接受合同条款。甲方通过电子平台下单的，甲方下单完成本合同即生效。电子合同、传真件、扫描件具有同等法律效力。
          </p>
          <div style="margin-top:25px">
            <span><span style="color:#000;font-weight:bold">特别提示：为充分保证您的权益，请您仔细阅读本合同。</span>
              本合同系<span style="color:#f00">服务内容</span>的有效组成部分，甲方签署<span style="color:#f00">服务内容项</span>或通过电子下单方式下单的视为已经仔细阅读本合同内容并无任何异议。</span>
          </div>
          <div style="margin-bottom: 20px;">(以下无正文)</div>
          <div style="padding-right:150px">
            <div style="display: flex;justify-content: space-between;">
              <span>甲方（签字/盖章）</span>
              <div style="position: relative;">
                <span>乙方（盖章）</span>
                <img class="bottom-img" v-if="form.order.carrierType.value === 1" src='../../../assets/images/huayueContract.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 2" src='../../../assets/images/hb.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 3" src='../../../assets/images/szhy.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 4" src='../../../assets/images/jiuzhi.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 5" src='../../../assets/images/gfrk.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 9" src='../../../assets/images/cdDQ.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 10" src='../../../assets/images/scDQ.png' />
                <img class="bottom-img" v-if="form.order.carrierType.value === 11" src='../../../assets/images/scDQXH.png' />
              </div>
            </div>
            <p style="display: flex;justify-content: space-between;">
              <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
              <span>签订日期：<span style="margin:0 40px">年</span>月<span style="margin-left:40px">日</span></span>
            </p>
          </div>
          <div style="height:50px"></div>
        </div>
      </div>
    </div>
    <template #footer>
      <a-space>
        <a-input addon-before="如果合同文字被分割，输入0-50可微调，以下载为准" v-model:value="checkNum" type='number'></a-input>
        <a-button @click="pdfDownload">下载</a-button>
        <a-button type="primary" v-print="'#printOrder'">打印</a-button>
      </a-space>
    </template>
  </a-modal>
  <a-modal v-model:visible="opsVisible" title="订单修改" width="65%" centered :bodyStyle="{ height: '75vh', overflow: 'auto' } " cancelText=' ' okText=' ' destroy-on-close @cancel="cancelMoadl">
    <OrderOps @close="close" :orderId="id" :isInvoice='form.order.isInvoice.value' :orderVehicles="form.orderVehicles" :label='form.order.carrierType?.label'></OrderOps>
  </a-modal>
</template>
<script>
import { push, detail, confirmTransport } from '@/api/trans/order'
import { onMounted, reactive, toRefs, createVNode } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import { amountUppercase } from '@/utils/util'
import { jsPDF as JsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import OrderOps from '@/views/opsCenter/compents/OrderOps'
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    OrderOps,
    ReloadOutlined,
    ExclamationCircleOutlined
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      insideName: '',
      opsVisible: false,
      modalVisible: false,
      modalVisibleHY: false,
      modalVisibleHB: false,
      printLoading: false,
      checkNum: 0,
      id: router.currentRoute.value.params.id,
      orderVehiclesCouponAmtSum: 0,
      form: {
        order: {
          isNoticeReleaseCar: { value: null },
          isDeliveryCard: { value: null },
          isInspectionPay: { value: null },
          carrierType: {},
          picker: {},
          consignor: {},
          isInvoice: {},
          isRefund: {},
          productId: '0',
          orderFund: {},
          orderSettlement: {},
          startAddress: {},
          endAddress: {},
          inspectPayRemark: ''
        },
        orderCustomer: {
          businessType: {}
        },
        orderVehicles: [],
        startAddress: [],
        endAddress: []
      },
      totalFee: 0.00,
      totalFeeChar: ''
    })
    const cancelMoadl = () => {
      data.form.orderVehicles.forEach(item => {
        item.checked = false
      })
    }
    const OrderModification = () => {
      data.opsVisible = true
    }
    const loadData = () => {
      detail(data.id, {}).then(res => {
        if (res.code === 10000) {
          if (res.data.order.invoiceCarrier) {
            res.data.order.carrierType.label = res.data.order.invoiceCarrier
          } else {
            res.data.order.carrierType.label = ""
          }
          data.form = res.data
          // console.log(data.form, 'data.form')
          data.totalFee = 0.00
          data.orderVehiclesCouponAmtSum = 0
          res.data.orderVehicles.forEach(item => {
            if (item.couponAmt !== null && Number(item.couponAmt) > 0) {
              data.orderVehiclesCouponAmtSum = Number(data.orderVehiclesCouponAmtSum) + Number(item.couponAmt)
            }
            data.totalFee = parseFloat(Number(data.totalFee) + Number(item.takeOutFee) + Number(item.placeInFee) + Number(item.insurancePremium) + Number(item.freight)).toFixed(2)
            item.totalFee = parseFloat(Number(item.takeOutFee) + Number(item.placeInFee) + Number(item.insurancePremium) + Number(item.freight)).toFixed(2)
            // item.placeInAddress = data.form.order.startAddress.details
            // item.takeOutAddress = data.form.order.endAddress.details
          })
          data.form.orderVehicles.forEach(item => {
            for (const key in item) {
              if (item[key] === null) item[key] = ''
            }
          })
          data.insideName = data.form.order.isInside.label
          // console.log(res, 'res')
          data.totalFeeChar = amountUppercase(data.totalFee)
        }
      })
    }
    const close = (boo) => {
      loadData()
      data.opsVisible = boo
    }
    onMounted(loadData)
    const reload = () => {
      loadData()
    }
    const edit = () => {
      // store.commit('closeTab', router.currentRoute.value.name)
      if (data.form.order.isInside && (data.form.order.isInside.value === 2 || data.form.order.isInside.value === 3)) {
        router.push(
          {
            name: 'orderAppletEdit',
            params: {
              id: data.id
            }
          }
        )
      } else {
        router.push(
          {
            name: 'orderEdit',
            params: {
              id: data.id
            }
          }
        )
      }
    }
    const send = () => {
      push(data.id).then(res => {
        if (res.code === 10000) {
          message.success('推送成功')
          loadData()
        }
      })
    }
    const backToInternal = () => {
      router.back()
      // router.push({
      //   name: 'internal'
      // })
    }
    // let allWidth = 0
    // let allHeight = 0
    const pdfDownload = async () => {
      // await printPdf()
      await html2canvas(document.querySelector('#printOrder')).then(canvas => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = contentWidth / 592.28 * 841.89
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // 页面偏移
        let position = 20
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 555.28
        const imgHeight = 552.28 / contentWidth * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const pdf = new JsPDF('', 'pt', 'a4')
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight - data.checkNum)
            leftHeight -= pageHeight
            position -= 841.89
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        pdf.save(`车辆运输协议-${data.form.order.orderId}.pdf`)
      })
    }
    const confirmOrder = () => {
      Modal.confirm({
        title: () => '确认订单?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          createVNode(
            'div',
            {
              style: 'color:red;',
            },
            '',
          ),
        onOk () {
          confirmTransport(data.form.order.orderId).then(res => {
            if (res.code === 10000) {
              loadData()
              message.success('确认成功')
            }
          })
        },
        onCancel () {
        },
        class: '',
      })
    }
    // // 判断是否需要添加空白div
    // const isSplit = (nodes, index, pageHeight) => {
    //   // 计算当前这块dom是否跨越了a4大小，以此分制
    //   if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
    //     return true
    //   }
    //   return false
    // }
    // const printPdf = () => {
    //   /* pdf出现分页时图片文宁被截断问题解决: 获取所有的需要下载的外层盒子，
    //   循环处理这些盒子，获取当前盒子距离顶部的高度offsetTop加上盒子的高度是否大于a4纸的高度，
    //   如果大于就在之前插入空白盒子，把内容挤下去。 */
    //   const A4WIDTH = 592.28
    //   const A4HEIGHT = 841.89
    //
    //   const target = document.querySelector('#printOrder')
    //   allWidth = target.offsetWidth
    //   allHeight = target.offsetHeight
    //   const pageHeight = allWidth / A4WIDTH * A4HEIGHT
    //
    //   // 获取分割dom，此处为class类名为item的dom
    //   const lableListID = document.querySelectorAll('.contract-content .item')
    //   // 进行分制操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
    //   for (let i = 0; i < lableListID.length; i++) {
    //     console.log((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight)
    //     const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight + 20) / pageHeight)
    //     if (isSplit(lableListID, i, (multiple * pageHeight + 20 + (multiple - 1) * 50))) {
    //       const divParent = lableListID[i].parentNode // 获取该div的父节点
    //       const newNode = document.createElement('div')
    //       newNode.className = 'emptyDiv'
    //       newNode.style.background = '#01195e'
    //       newNode.style.height = '50px'
    //       newNode.style.width = '100%'
    //       newNode.innerText = '----------------------------'
    //       const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
    //       // 判断兄弟节点是否存在
    //       if (next) {
    //         // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
    //         divParent.insertBefore(newNode, next)
    //       } else {
    //         // 不存在则直接添加到最后,appendchild默认添加到divParent的最后
    //         divParent.appendChild(newNode)
    //       }
    //     }
    //   }
    //   /* 执行下载并打印PDF的方法 */
    //   // this.pdfs(pdfDom, title);
    // }
    return {
      ...toRefs(data),
      edit,
      send,
      confirmOrder,
      pdfDownload,
      backToInternal,
      reload,
      close,
      cancelMoadl,
      OrderModification
    }
  }
}
</script>
<style lang="less" scoped>
.card-box {
  height: 780px;
  overflow: auto;
}
.ant-typography {
  margin: 0;
}

.suspension-button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 16px;
}

.table-orderDetail {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
    white-space: nowrap;
  }

  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    // border: 1px solid #c4dff5;
    border: 1px solid #000;
    max-width: 300px;
  }

  .left-td {
    text-align: left;
  }

  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

.vehicle-div {
  background: #eee;
}

.contract {
  &-title {
    text-align: center;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-mid {
    border: 1px solid #000;

    &-label {
      border-bottom: 1px solid #000;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &-value {
      text-indent: 1em;
      border-bottom: 1px solid #000;

      p {
        padding-top: 5px;
      }
    }

    &-text {
      border-bottom: 1px solid #000;

      p {
        text-indent: 2em;
      }
    }

    &-motion {
      color: red;

      p {
        margin: 0;
      }

      &-sign {
        width: 50%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        b {
          flex: 1;
          color: #000;

          span {
            display: inline-block;
            font-weight: normal;
            margin-left: 50px;
          }
        }
      }
    }
  }

  &-head {
    &-label {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-align: left;
      text-indent: 1em;
    }
  }

  &-content {
    font-size: 16px;
    text-indent: 2em;

    p {
      margin-bottom: 15px;
    }

    h1 {
      text-align: center;
    }

    &-sort {
      font-size: 18px;
      font-weight: 600;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      text-indent: 2em;
    }

    // &-label-title{
    //   font-size: 18px;
    //   font-weight: bold;
    // }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-indent: 1em;
      text-align: left;
      height: 24px;
    }

    &-right {
      text-align: right;
      padding-right: 2em;
    }
  }

  &-footer {
    text-indent: 2em;
    margin-bottom: 8px;

    &-label {
      font-size: 16px;
      font-weight: 600;
    }

    &-value {
      font-size: 16px;
      text-indent: 1em;
    }
  }

  &-seal {
    width: 230px;
    margin-top: -120px;
    margin-left: 65%;
    filter: opacity(0.7);
  }
}

.bottom-img {
  position: absolute;
  width: 230px;
  top: -120px;
  left: 0%;
  filter: opacity(0.7);
}
</style>
