<template>
  <div>
    <a-card :bodyStyle="{ padding: 50 }">
      <template #title>
        <a @click="chooseCustomerStatus = true">选择新客户</a>
      </template>
      <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
        <a-row :gutter="16">
          <a-col :span="24">
            <p>新客户名称： <span>{{ customerName }}</span></p>
            <a-form-item label="原因">
              <a-textarea v-model:value="reason" :rows="6" :maxlength="1000" showCount />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-button type="primary" @click="submitText" :disabled="form.customerId.length <= 0">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <ChooseCustomer v-if="chooseCustomerStatus" v-model:visible="chooseCustomerStatus" @select="chooseCustomer" />
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import ChooseCustomer from '@/views/components/chooseCustomer'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  components: {
    ChooseCustomer
  },
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      chooseCustomerStatus: false,
      reason: '',
      customerName: '',
      form: {
        orderId: props.orderId,
        customerId: ''
      }
    })
    const chooseCustomer = item => {
      if (item.name !== '' && item.name !== null && item.name !== undefined) {
        state.customerName = item.name
        state.form.customerId = item.id
      }else {
        message.error('该客户名称为空，请到合作客户更改该客户名称')
        state.customerName = ''
        state.form.customerId = ''
      }
    }
    const submitText = () => {
      orderPickEdit({
        params: state.form,
        type: {
          value: 0
        },
        reason: state.reason,
        subType: props.subType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.reason = ''
          emit('success', false)
        }
      })
    }
    return {
      ...toRefs(state),
      chooseCustomer,
      submitText
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}

.not-archive {
  color: #999;
}
</style>
