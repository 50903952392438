<template>
  <div>
    <a-table ref="tableRef" :dataSource="orderVehicles" :rowKey="item => item.orderVehicleId" :columns="columns" size="small" :pagination="false" :scroll="{y:200}">
      <template #customAlarmTimeTitle="{ column }">
        <template v-if="!props.singleChoice">
          <a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">
          </a-checkbox>
        </template>
      </template>
      <template #add="{ record }">
        <a-checkbox v-model:checked="record.checked" @change="change(record)"></a-checkbox>
      </template>
      <template #vinNo="{ record }">{{ record.vinNo }}{{ record.brand }}{{ record.model }}&nbsp;{{ record.vehicleType.label }}</template>
      <template #insuranceCompany="{ record }">
        {{ record.insuranceCompany.label }}￥{{ record.insuranceFee }}万元&nbsp;&nbsp;&nbsp;￥{{ record.insurancePremium }}
      </template>
      <template #freight="{ record }">
        ￥{{ record.freight }}
      </template>
      <template #pickType="{ record }">
        <div v-if="record.placeInAddress">
          ￥{{ record.placeInFee }}&nbsp;{{ record.pickType.label }}&nbsp;{{ record.placeInAddress }}
        </div>
        <div v-else>
          不取车
        </div>
      </template>
      <template #takeType="{ record }">
        <div v-if="record.takeOutAddress">
          ￥{{ record.takeOutFee }}&nbsp;{{ record.takeType.label }}&nbsp;{{ record.takeOutAddress }}
        </div>
        <div v-else>
          不送车
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
  orderVehicles: {
    type: Array,
    default: []
  },
  singleChoice: {
    type: Boolean,
    default: false
  }
})
const checkAll = ref(false)
const indeterminate = ref(false)
const onCheckAllChange = (e) => {
  if (e.target.checked) {
    props.orderVehicles.forEach(item => {
      item.checked = true
    })
  } else {
    indeterminate.value = false
    props.orderVehicles.forEach(item => {
      item.checked = false
    })
  }
}
const change = (e) => {
  if (!props.singleChoice) {
    let list = props.orderVehicles.filter(item => item.checked)
    indeterminate.value = !!list.length && list.length < props.orderVehicles.length;
    checkAll.value = list.length === props.orderVehicles.length;
    return
  }
  props.orderVehicles.forEach(item => {
    if (e.orderVehicleId !== item.orderVehicleId) {
      item.checked = false
    }
  })
}


const columns = ref([{
  dataIndex: 'add',
  slots: {
    customRender: 'add',
    title: 'customAlarmTimeTitle'
  },
  align: 'center',
  width: 50,
},
{
  title: '车牌/车型',
  dataIndex: 'vinNo',
  slots: {
    customRender: 'vinNo'
  },
  align: 'center',
},
{
  title: '车辆状态',
  dataIndex: 'status.label',
  align: 'center',
},
{
  title: '运费',
  dataIndex: 'freight',
  slots: {
    customRender: 'freight'
  },
  align: 'center',
},
{
  title: '保险信息',
  dataIndex: 'insuranceCompany',
  slots: {
    customRender: 'insuranceCompany'
  },
  align: 'center',
},
{
  title: '预约时间',
  dataIndex: 'pickAppointTime',
  slots: {
    customRender: 'pickAppointTime'
  },
  align: 'center',
},
{
  title: '取车信息',
  dataIndex: 'pickType',
  slots: {
    customRender: 'pickType'
  },
  align: 'center',
},
{
  title: '送车信息',
  dataIndex: 'takeType',
  slots: {
    customRender: 'takeType'
  },
  align: 'center',
},
])
</script>

<style lang="less" scoped>
</style>