<template>
  <div>
    <OrderTable :orderVehicles="orderVehicles"></OrderTable>
    <a-card :bodyStyle="{padding:50}">
      <template #title>
        预约时间修改
      </template>
      <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="预约时间" name="time">
              <a-date-picker :disabled-date="disabledDate" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="选择时间" v-model:value="form.time" @change="reservationTime" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="原因" name="reason">
              <a-textarea v-model:value="form.reason" :rows="6" :maxlength="1000" showCount />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-button type="primary" @click="submitText">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
</template>

<script setup>
import moment from 'moment'
import { ref } from 'vue'
import OrderTable from './OrderTable.vue'
import { reactive } from 'vue';
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue';
const props = defineProps({
  orderVehicles: {
    type: Array,
    default: []
  },
  orderId: { type: String, default: '' },
  subType: {}
})
const emit = defineEmits(['success'])
const rules = ref({
  time: { required: true, message: '请选择', trigger: 'change', type: 'string' },
  reason: { required: true, message: '请填写原因', trigger: 'change', type: 'string' }
},)
const form = reactive({
  time: '',
  reason: ''
})
const formRef = ref(null)

const reservationTime = (e, v) => {
  form.time = v
}
 // 禁用时间
 const disabledDate = current => {
  return current && current < moment().subtract(1,"days");
    }
const submitText = () => {
  let orderVehicleIds = []
  props.orderVehicles.forEach(item => {
    if (item.checked) {
      orderVehicleIds.push(item.orderVehicleId)
    }
  })
  if (orderVehicleIds.length === 0) {
    return message.error('请选择车辆')
  }
  formRef.value.validate().then(res => {
    let params = {
      time: form.time,
      orderVehicleIds: orderVehicleIds,
      orderId: props.orderId
    }
    orderPickEdit({
      params: params,
      type: {
        value: 0
      },
      reason: form.reason,
      subType: props.subType
    }).then(res => {
      if (res.code === 10000) {
        message.success(res.msg)
        emit('success', false)
      }
    })
  })
}
</script>

<style lang="less" scoped>
</style>